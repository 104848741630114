.dash {
    // background-color: var(--COLOR-BG);
    padding: 20px 40px;
    margin-left: 240px;

    &.open {
        // padding-left: 240px ;
    }
}

.sidebarGrid {
    display: grid;
    gap: 16px;
    padding: 16px;
}

.partners {
    display: flex;
    margin: 16px;
    align-items: center;

    button {
        height: 36px;
        margin-right: 10px;
    }

    img {
        height: 50px;
        // margin-top: -40px;
        display: block;
    }
}

.charts {
    display: flex;
    padding: 16px;
    margin: 20px 16px;
    border: solid 1px white;
    border-radius: 6px;
    align-items: flex-start;
    --chart-width: 600px;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    transition: all ease .3s;

    // .chartContainer {
    //     height: 700px;
    // }

    .chartTitle {
        position: absolute;
        top: -12px;
        left: 16px;
        background-color: #121212;
        padding: 0 6px;
    }

    .chart {
        height: calc(var(--chart-width) / 4);
        width: var(--chart-width);

        &.mobile {
            width: 300px;
            height: var(--chart-width);
        }

        > span {
            color: white;
        }
    }

    .form {
        min-width: 200px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;

        .item {
            display: flex;
            justify-content: center;
        }
    }
}

.line {
    height: 200px;
}