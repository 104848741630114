.modal {
    display: flex;
    align-items: center;
    justify-content: center;

    :global(.rodal-dialog) {
        padding: 0;
        border: none;
        background: none;
        position: relative;
        height: auto !important;

        > div {
            padding: 15px;
            padding-top: 30px;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    
}

.alert {
    :global(.rodal-dialog) {
        padding: 0;
        border: none;
        background: none;
        height: 180px !important;

        > div {
            padding: 15px;
            padding-top: 0;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            :global(.material-icons) {
                margin-top: 15px;
                margin-left: 10px;
            }
        }
    }
}