.wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    background-color: var(--COLOR-BG);
}

.container {
    display: flex;
    flex-direction: column;

    >div {
        margin-bottom: 16px;
    }
}